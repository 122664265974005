<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'credit_auth' }">
          <button class="btn btn-control">
            <img src="@/assets/icons/bold-icon previous.svg" alt="Return back">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">New Request</h3>
        <span class="subtitle">Request Signature from Participant</span>
      </div>
    </div>

    <!--  Page Content  -->
    <div class="page-content p-0">
      <div class="row w-100 m-auto">
        <!--  Preview  -->
        <div class="col-md-8 border-right-light">
          <vue-html2pdf
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            filename="hee hee"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            pdf-orientation="portrait"
            ref="html2Pdf"
          >
            <section slot="pdf-content">
              <credit-auth-preview :docsData="docData" />
            </section>
          </vue-html2pdf>
        </div>

        <!--  Preview Data  -->
        <div class="col-md-4 pt-4 pe-4 ps-4">
          <div>
            <!--  Col Title  -->
            <div class="col-title d-flex align-items-center">
              <span>Participant Details</span>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group">
                  <label for="firstNameField">First Name</label>
                  <input type="text"
                         v-model="docData.firstName" id="firstNameField"
                         :class="{ 'input-danger': getFirstError('firstName', errors) }" class="form-control">
                  <p v-if="getFirstError('firstName', errors)">{{ getFirstError('firstName', errors) }}</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="lastNameField">Last Name</label>
                  <input type="text" id="lastNameField" v-model="docData.lastName" class="form-control" :class="{ 'input-danger': getFirstError('firstName', errors) }">
                  <p v-if="getFirstError('lastName', errors)">{{ getFirstError('lastName', errors) }}</p>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group w-100 full-width">
                  <label for="addressField">Address</label>
                  <input type="text" v-model="docData.address" id="addressField" class="form-control" :class="{ 'input-danger': getFirstError('address', errors) }">
                  <p v-if="getFirstError('address', errors)">{{ getFirstError('address', errors) }}</p>
                </div>

              </div>
              <div class="row mt-3 w-100 p-0 m-auto">
                <div class="col-6">
                  <div class="form-group">
                    <label for="cityField">City</label>
                    <input type="text" v-model="docData.city" class="form-control" id="cityField" :class="{ 'input-danger': getFirstError('city', errors) }">
                    <p v-if="getFirstError('city', errors)">{{ getFirstError('city', errors) }}</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for="stateField">State</label>
                    <input type="text" v-model="docData.state" class="form-control" id="stateField" :class="{ 'input-danger': getFirstError('state', errors) }">
                    <p v-if="getFirstError('state', errors)">{{ getFirstError('state', errors) }}</p>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for="zipField">Zip</label>
                    <input type="text" v-model="docData.zip" class="form-control" id="zipField" :class="{ 'input-danger': getFirstError('zip', errors) }">
                    <p v-if="getFirstError('zip', errors)">{{ getFirstError('zip', errors) }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="form-group full-width w-100">
                  <label for="emailField">Email Address</label>
                  <input type="text" v-model="docData.email" class="form-control" id="emailField" :class="{ 'input-danger': getFirstError('email', errors) }">
                  <p v-if="getFirstError('email', errors)">{{ getFirstError('email', errors) }}</p>
                </div>
              </div>
              <div class="col-12 mt-3">
                <base-button title="Send Document for Signature" action="secondary-default" :loading="ui.saving"
                             @click-btn="sendRequest" :disabled="ui.saving" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import BaseButton from '../../../components/BaseButton.vue';
import CreditAuthPreview from "../preview/CreditAuthPreview";

export default {
  name: 'CreditAuthNewRequest',
  components: {CreditAuthPreview, VueHtml2pdf, BaseButton},
  data() {
    return {
      ui: {
        loading: false,
        saving: false,
      },
      docData: {
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        email: '',
      },
      errors: []
    }
  },
  methods: {
    sendRequest() {
      this.ui.saving = true

      this.$http.post('/api/v1/credit-auth/create', this.docData)
        .then(response => {
          this.$router.push({name: 'credit_auth_details', params: { uuid: response.data.doc.uuid }})
        })
        .catch(error => {
          this.errors = error.response.data.errors
          this.ui.saving = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.border-right-light {
  border-right: 1px solid #e7e8e8;
  @media screen and (max-width: 568px) {
    border: none;
  }
}
.col-title {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}
.form-group {
  label {
    color: rgba(0,0,0,.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;
  }
  input {
    height: 36px;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
    background-color: #FFFFFF;
  }
  p {
    color: #D20A3B;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 18px;
    margin: 0;
  }
}
.request-done {
  padding: 11px 18px 11px 18px;
  margin-bottom: 24px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  border: 1px solid rgba(0,215,104,0.4);
  border-radius: 4px;
  background-color: rgba(0,215,104,0.1);
}
.table {
  tr {
    td {
      height: 36px;
      padding: 10px 12px;
      &:first-child {
        color: rgba(0,0,0,.7);
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;
        font-weight: normal;
      }
      color: #000000;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
      font-weight: normal;
    }
  }
}
button {
  &.btn-outline-grey {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    width: fit-content;
    padding: 0 21.5px;
    color: rgba(0,0,0,.7);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    border-radius: 8px;
    border: 1px solid #8A8A8A;
    transition: all .3s;
    &:hover {
      background: #eeeeee;
    }
  }
  &.btn-primary {
    min-width: 220px;
  }
}

#pdf-generate {
  max-width: 791px;
  width: 100%;
  height: 1000px;
}

.vue-html2pdf::v-deep {
  .content-wrapper {
    margin: 30px auto;
  }
  .layout-container {
    position: initial!important;
    width: inherit!important;
    height: initial!important;
    background: none !important;
  }
}
</style>
